export const CardData = [
  {
    id: 1,
    CourseName: "Complete Visitor Visa Course",
    AUD: 25000,
    ClassTime: "Evening according to Batch",
    Duration: "2/3 Months",
    CountriesIncluded:
      "Australia, Canada, New Zealand, Schengen, UK, USA and 20 more countries",
    CourseContent:
      "Filling-up visa forms, writing covering letters, managing financial documents & client profile, complete step by step process of applying and taking visa, buying travel insurance & cancelling without any loss, making hotel vouchers by paying or without paying, making travel itinerary, making air tickets or dummy air tickets, taking embassy or VFS appointment, submitting passport, collecting passport, authority letter benefits and format, biometric & passport submission, collecting passport after visa stamping, registering & running visa business, writing no objection letters and format of NOC, how and how much to charge from your client & how to make profit, making beneficial and acceptable refund policy,making complete visa file, client counselling.",
    
  },
  {
    id: 2,
    CourseName: "Complete Work Visa Course",
    ClassTime: "Evening according to Batch",
    Duration: "1/2 Months",
    CourseContent: "Filling - up work permit visa forms, making appropriate covering letters, making work candidate resume, apostle process, police clearance certification process, process of taking appointment when appointments not available, showing available jobs and direct contact of hiring companies in respective country.",
    AUD: 25000,
  },
  {
    id: 3,
    CourseName: "Complete Student Visa Course",
    ClassTime: "Evening according to Batch",
    Duration: "2/3 Months",
    CourseContent: "counselling students,understanding each country's student eligiblites,finding colleges with or without IELTS, finding & taking admission in most appropriate college, institute, university, paying fees to educational institute, contracting new universities, GIC or fees transfer process as per country.",
    
    AUD: 25000,
  },
  {
    id: 4,
    CourseName: "Entire World Every Type of Visa",
    ClassTime: "Evening according to Batch",
    Duration: "3/4 Months",
    CourseContent: "Full assistance provided to start your own travel company",    
    AUD: 60000,
    ClassDuration:"2 to 3 hour",
    ReturnP:"Return of Investment easily possible within 3 months"
  },
  {
    id: 5,
    CourseName: "Learning Single Countries",
    ClassTime: "Evening according to Batch",
    Duration: "2/3 Months",
    CourseContent: "Full assistance provided to start your own travel company",   
    AUD: 10000,
    ClassDuration:"2 to 3 hour",
  },

];
